import axios from "axios";

const CORS_PROXY =
  "https://4fl2ql0l15.execute-api.us-east-1.amazonaws.com/dev/proxy";

export const getPlaceID = async (input: string) => {
  const response = await axios.post(CORS_PROXY, {
    placeIdRequest: {
      input,
    },
  });

  return response.data.placeId;
};

export const getPlaceDetails = async (placeId: string) => {
  const response = await axios.post(CORS_PROXY, {
    placeDetails: {
      placeId,
    },
  });

  return response.data;
};

export const getReviews = (placeDetails: any): string[] => {
  return placeDetails.reviews.map((review: any) => review.text).filter((review: string) => review.trim().length >= 3);
}
