import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  Stack,
  ListItemIcon,
  Collapse,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  ListItemButton,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

interface Props {
  onSearch: (query: string) => void;
}

const SearchLocation: React.FC<Props> = ({ onSearch }) => {
  const [query, setQuery] = useState("");
  const [instructionsExpanded, setInstructionsExpanded] = useState(false);

  const handleSubmit = () => {
    onSearch(query);
  };

  const handleInstructionsToggle = () => {
    setInstructionsExpanded(!instructionsExpanded);
  };

  const examples = [
    "Parkside Apartments By Lincoln",
    "Beacon Plumbing",
    "Discovery Park, Seattle",
  ];

  const onClickExample = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    example: string
  ) => {
    event.preventDefault();
    setQuery(example);
  };

  const instructions = [
    {
      text: "Enter the name of your business or a specific location. It can be the name of local business, an apartment complex, or even a nearby park. See the examples below:",
      nestedList: (
        <List sx={{ pl: 4 }}>
          {examples.map((example, index) => (
            <ListItem key={"sublist" + index} sx={{ my: 0, py: 0 }}>
              <ListItemIcon
                sx={{ fontSize: "small", mr: 0, pr: 0, minWidth: "24px" }}
              >
                <CircleIcon sx={{ fontSize: "small", mr: 0, pr: 0 }} />
              </ListItemIcon>
              <a href="#" onClick={(e) => onClickExample(e, example)}>
                {example}
              </a>
            </ListItem>
          ))}
        </List>
      ),
    },
    "Click the 'Submit' button to analyze the location.",
    "Wait for the analysis results to be displayed below the button.",
  ];

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="20vh"
        marginTop="10vh"
      >
        <Typography variant="h2" align="center" gutterBottom>
          Business Review Analyzer
        </Typography>
        <Typography variant="body1" align="left" marginBottom={2}>
          The only tool that uses AI to analyze the reviews of your business!
          Get a clear summary of your business's strengths and weaknesses with
          the click of a button.
        </Typography>
        <TextField
          label="Location"
          variant="outlined"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          fullWidth
        />
        <Box mt={2} mb={4}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={query === ""}
          >
            Analyze
          </Button>
        </Box>
      </Box>

      <List>
        <ListItemButton sx={{pl: 0, ml: 0}} onClick={handleInstructionsToggle}>
          <ListItemText
            primary="How To Use"
            primaryTypographyProps={{ variant: "h6" }}
          />
          {instructionsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>
        <Collapse in={instructionsExpanded}>
          <List>
            {instructions.map((instruction, index) => (
              <React.Fragment key={index}>
                <ListItem sx={{ my: 0, py: 0 }}>
                  <ListItemText
                    primary={`${index + 1}. ${
                      typeof instruction === "string"
                        ? instruction
                        : instruction.text
                    }`}
                  />
                </ListItem>
                {typeof instruction !== "string" && instruction.nestedList}
              </React.Fragment>
            ))}
          </List>
        </Collapse>
      </List>
    </>
  );
};

export default SearchLocation;
