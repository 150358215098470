import React, { useState } from "react";
import {
  Chip,
  Container,
  Typography,
  Stack,
  Button,
  TableHead,
  Toolbar,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";

import SearchLocation from "./components/SearchLocation";
import { getPlaceDetails, getPlaceID, getReviews } from "./api/googlePlaces";
import { analyzeReviews } from "./api/openAI";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { sleep } from "./utils/utils";

const App: React.FC = () => {
  const [query, setQuery] = useState<string>("");
  const [placeDetails, setPlaceDetails] = useState<any>(null);
  const [results, setResults] = useState<string[]>([]);

  const handleSearch = async (userQuery: string) => {
    setResults(["Loading"]); // Clear previous results
    setQuery(userQuery);

    // if (userQuery === query) {
    //   console.log('same input was given, no need to fetch new results');
    //   return;
    // }

    try {
      const placeID = await getPlaceID(userQuery);
      if (!placeID) {
        setResults([`We're sorry, we could not find the location "${userQuery}"`]);
        return;
      }
      console.log("got placeID", placeID);
      console.log("sending query", userQuery);

      const placeDetails = await getPlaceDetails(placeID);
      setPlaceDetails(placeDetails);
      console.log("received place details", placeDetails);
      const reviews: string[] = getReviews(placeDetails);
      console.log("received reviews", reviews);

      setResults(["Analyzing results..."]);
      if (reviews.length < 3) {
        setResults([`We're sorry, we couldn't find enough reviews to analyze for ${userQuery}`]);
        return;
      }

      const analysis = await analyzeReviews(reviews);
      console.log("analysis", analysis.split("\n"));
      setResults(analysis.split("\n"));
    } catch (error) {
      console.error("Error:", error);
      setResults(["An error occurred while processing the request."]);
    }
  };

  // const exportToPDF = async () => {
  //   const resultsElement = document.getElementById("results");
  //   if (resultsElement) {
  //     const canvas = await html2canvas(resultsElement);
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF({
  //       orientation: "portrait",
  //       unit: "mm",
  //       format: "a4",
  //     });
  //     const imgProps = pdf.getImageProperties(imgData);
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  //     pdf.addImage(imgData, 0, 10, pdfWidth, pdfHeight);
  //     pdf.save("analyzed-results.pdf");
  //   }
  // };

  const showPlaceDetails = () => {
    if (!placeDetails) {
      return <></>;
    }

    // return (
    //   <Typography>
    //     Showing results for {placeDetails.name}
    //   </Typography>
    // )
    return <></>;
  };

  const showResults = () => {
    if (results.length <= 1) {
      return <Typography align="center">{results[0]}</Typography>;
    }

    // Assuming you have already calculated the overall sentiment

    // Separate the positive and negative reviews
    const positiveReviews = results.filter((result) => result.startsWith("+"));
    console.log("positiveReviews", positiveReviews);
    const negativeReviews = results.filter((result) => result.startsWith("-"));
    const totalReviewLength = positiveReviews.length + negativeReviews.length;
    console.log("negativeReviews", negativeReviews);
    const overallSentiment = (positiveReviews.length / totalReviewLength) - (negativeReviews.length / totalReviewLength);

    // Find the maximum length between the two lists
    const maxLength = Math.max(positiveReviews.length, negativeReviews.length);

    return (
      <>
        {/* <Typography variant="h6">Location: </Typography> */}

        <Paper elevation={3} sx={{mb: 4}}>
          <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
            <Stack direction="column">
              <Typography
                variant="h6"
                id="tableTitle"
                component="div"
              >
                {placeDetails?.name ? placeDetails.name : query}
              </Typography>

              <Typography variant="subtitle2">
                Overall Sentiment: {overallSentiment}
              </Typography>
            </Stack>
          </Toolbar>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{backgroundColor: "rgb(211, 237, 209)", width: "50%"}}>
                    <Typography variant="subtitle1" >Positives</Typography>
                  </TableCell>
                  <TableCell sx={{backgroundColor: "rgb(237, 209, 209)", width:"50%"}}>
                    <Typography variant="subtitle1">Negatives</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from({ length: maxLength }).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {index < positiveReviews.length
                        ? positiveReviews[index]
                        : ""}
                    </TableCell>
                    <TableCell>
                      {index < negativeReviews.length
                        ? negativeReviews[index]
                        : ""}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </>
    );
  };

  const showCallToAction = () => {
    if (results.length <= 1) {
      return <></>;
    }
    return (
      <>
        {/* <Button variant="contained" onClick={exportToPDF}>
          Download as PDF
        </Button> */}
        {/* <br /> */}
        <Typography>
          We provide daily, weekly, or monthly comprehensive reports that
          analyze all reviews for your business. These reports will highlight
          how your customers think of you. To start, the report will list out
          the positives, the negatives, and can rank the severity of the
          strength or weakness. We can use data from Google, Zillow, Yelp,
          Apartments.com, and more.
          <br />
          <br />
          Want a custom report with additional details and insights? We do those
          too!
          <br />
          <br />
          Get in contact with{" "}
          <a
            href={`mailto:brystephor@gmail.com?subject=Review Analysis for ${query}`}
          >
            brystephor@gmail.com
          </a>{" "}
          today to begin getting reports that show how your customers think of
          you!
        </Typography>
      </>
    );
  };

  return (
    <Container maxWidth="md" sx={{ pt: "32px" }} id="results">
      <SearchLocation onSearch={handleSearch} />
      {showPlaceDetails()}
      {showResults()}
      {showCallToAction()}
    </Container>
  );
};

export default App;
