import axios from "axios";

const CORS_PROXY =
  "https://4fl2ql0l15.execute-api.us-east-1.amazonaws.com/dev/proxy";

  export const analyzeReviews = async (reviews: string[]): Promise<string> => {
    const prompt = `
    Analyze the following reviews and find the top 3 most commonly mentioned negative items and the top 5 most commonly mentioned positive items in them. 
    Summarize the recurring negatives and recurring positives into separate bullet point lists. 
    The positive points should be labeled "Positives" and each point start with a "+" sign. The negative points should start with a "-" sign.\n\n
    ${reviews.join("\n\n")}
    
    \n\nCommon patterns:\n
    `;
  
    const response = await axios.post(CORS_PROXY, {
      analyzeReviews: {
        prompt,
      },
    });
  
    return response.data.result;
  };
